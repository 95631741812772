// Stock price simulation
class StockTicker {
  constructor() {
    // Generate random initial price history between 80 and 120
    let i = 0.5;
    this.priceHistory = Array(50).fill(0).map(() => {
      i += Math.random() * 0.2;
      return i;
    });
    this.stockPrices = document.querySelectorAll('.stock-price');
    this.sparklines = document.querySelectorAll('.sparkline');
    this.updateInterval = null;
  }

  updatePrice() {
    const volatility = 0.02;
    const lastPrice = parseFloat(this.priceHistory[this.priceHistory.length - 1]);
    const change = Math.min(10 - Math.random() * 2,lastPrice * volatility * (Math.random()));
    const newPrice = Math.max(0.01, lastPrice + change);

    this.priceHistory.push(newPrice);
    this.priceHistory.shift();

    this.stockPrices.forEach(el => {
      const oldPrice = parseFloat(el.textContent.replace('$', ''));
      el.textContent = `$${newPrice.toFixed(2)}`;
      el.className = 'stock-price ' + (newPrice > oldPrice ? 'up' : 'down');
    });

    this.drawSparklines();
  }

  drawSparklines() {
    this.sparklines.forEach(canvas => {
      const ctx = canvas.getContext('2d');
      const width = canvas.width = 60;
      const height = canvas.height = 20;

      const max = Math.max(...this.priceHistory);
      const min = Math.min(...this.priceHistory);
      const range = max - min;

      ctx.clearRect(0, 0, width, height);
      ctx.strokeStyle = '#ffffff';
      ctx.lineWidth = 1;
      ctx.beginPath();

      this.priceHistory.forEach((price, i) => {
        const x = (i / (this.priceHistory.length - 1)) * width;
        const y = height - ((price - min) / range) * height;
        if (i === 0) ctx.moveTo(x, y);
        else ctx.lineTo(x, y);
      });

      ctx.stroke();
    });
  }

  start() {
    this.updatePrice();
    this.updateInterval = setInterval(() => this.updatePrice(), 500);
  }

  stop() {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
      this.updateInterval = null;
    }
  }
}

export function initializeStockTicker() {
  const ticker = new StockTicker();
  ticker.start();
  return ticker;
}