class LiveCam {
  constructor() {
    this.container = document.getElementById('mini-player');

    // Create live-cam container
    this.liveCamContainer = document.createElement('div');
    this.liveCamContainer.className = 'live-cam-container';
    this.container.appendChild(this.liveCamContainer);

    // Create animated GIF
    this.gif = document.createElement('img');
    this.gif.src = '/imgs/live-feed.gif';
    this.gif.className = 'live-cam-gif';
    this.liveCamContainer.appendChild(this.gif);

    // Create timestamp overlay
    this.timestamp = document.createElement('div');
    this.timestamp.className = 'timestamp-overlay';
    this.liveCamContainer.appendChild(this.timestamp);

    // Create now playing display
    this.nowPlaying = document.createElement('div');
    this.nowPlaying.className = 'now-playing visible';
    this.tracks = [
      { path: '/mp3/desolationboys.mp3', title: 'Desolation Boys', duration: 276 }, // 4m 36s
      { path: '/mp3/thetfd.mp3', title: 'The TFD', duration: 220 }, // 3m 40s
      { path: '/mp3/him.mp3', title: 'Him', duration: 234 }         // 3m 54s
    ];
    this.currentTrack = this.tracks[0]; // Set initial track
    this.updateNowPlaying();
    this.liveCamContainer.appendChild(this.nowPlaying);

    // Create play button
    this.playButton = document.createElement('div');
    this.playButton.className = 'play-button';
    this.liveCamContainer.appendChild(this.playButton);

    // Audio setup
    this.audio = new Audio();
    this.audio.addEventListener('ended', () => this.playNextTrack());

    this.setupEventListeners();
    this.startTimestampUpdate();
  }

  setupEventListeners() {
    this.playButton.addEventListener('click', () => {
      if (this.audio.paused) {
        this.startPlayback();
      } else {
        this.pausePlayback();
      }
    });
  }

  startTimestampUpdate() {
    const updateTimestamp = () => {
      const now = new Date();
      const date = now.toLocaleDateString('en-US', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit'
      }).replace(/\//g, '.');
      const time = now.toLocaleTimeString('en-US', {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
      this.timestamp.textContent = `REC ${date} ${time}`;
    };

    updateTimestamp();
    setInterval(updateTimestamp, 1000);
  }

  getCurrentTrackAndTime() {
    const totalCycleDuration = this.tracks.reduce((sum, track) => sum + track.duration, 0);
    const midnight = new Date();
    midnight.setHours(0, 0, 0, 0);

    const secondsSinceMidnight = (Date.now() - midnight.getTime()) / 1000;
    const currentCycleTime = secondsSinceMidnight % totalCycleDuration;

    let accumulatedTime = 0;
    for (let i = 0; i < this.tracks.length; i++) {
      accumulatedTime += this.tracks[i].duration;
      if (currentCycleTime < accumulatedTime) {
        const trackTime = this.tracks[i].duration - (accumulatedTime - currentCycleTime);
        return {
          track: this.tracks[i],
          time: trackTime
        };
      }
    }

    return {
      track: this.tracks[0],
      time: 0
    };
  }

  startPlayback() {
    const { track, time } = this.getCurrentTrackAndTime();
    this.currentTrack = track;
    this.audio.src = track.path;
    this.audio.currentTime = time;
    this.audio.play();
    this.playButton.classList.add('playing');
    this.updateNowPlaying();
  }

  pausePlayback() {
    this.audio.pause();
    this.playButton.classList.remove('playing');
  }

  playNextTrack() {
    const currentIndex = this.tracks.indexOf(this.currentTrack);
    const nextIndex = (currentIndex + 1) % this.tracks.length;
    this.currentTrack = this.tracks[nextIndex];
    this.audio.src = this.currentTrack.path;
    this.audio.play();
    this.updateNowPlaying();
  }

  updateNowPlaying() {
    this.nowPlaying.textContent = `Total Fucking Darkness "${this.currentTrack.title}" Live in Studio`;
  }
}

export function initializeLiveCam() {
  return new LiveCam();
}
